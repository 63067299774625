import Vue from 'vue'
import VueRouter from 'vue-router'
import BikesComp from "@/components/Bike/BikesComp";
import AddBike from '../components/Bike/AddBike.vue'
import LoginPage from "../components/Navigation/LoginPage"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },

    {
        path: '/home',
        name: 'BikesComp',
        component: BikesComp
    },
    {
        path: '/addBike',
        name: 'AddBike',
        component: AddBike
    },
    {
        path: "*/",
        name: 'LoginPage',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
