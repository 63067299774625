import { render, staticRenderFns } from "./BikesComp.vue?vue&type=template&id=53f27cc0&scoped=true&"
import script from "./BikesComp.vue?vue&type=script&lang=js&"
export * from "./BikesComp.vue?vue&type=script&lang=js&"
import style0 from "./BikesComp.vue?vue&type=style&index=0&id=53f27cc0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!C:\\Users\\Mobivone\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f27cc0",
  null
  
)

export default component.exports