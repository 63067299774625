<template>
  <v-container fluid class="text-center pa-8">
    <!-- <v-divider class="mt-1"></v-divider> -->

    <v-card elevation="0" outlined width="100%" align="center" class="ma-auto">
      <v-card-title class="grey lighten-4" dense>
        <v-row class="ps-3 pe-3">
          <v-btn
              class="customBtnColor white--text ma-0 me-3 back-btn"
              to="/home"
              depressed
              outlined
              tile
          >
            <i class="mgc_left_line back-icon"></i>
          </v-btn>
          <h3 class="AddBikeBtn">Add Bike</h3>
          <v-spacer></v-spacer>
          <v-btn
              @click="AddBike"
              depressed
              outlined
              tile
              class="AppBtn grey lighten-1 white--text"
          >
            Add bike
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text class="pa-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-12">
                  <fieldset class="fieldsetStyle" label="Bike" outlined>
                    <legend class="field-legend">General</legend>

                    <v-row>
                      <v-col cols="12" md="6" class="pe-md-0">
                        <v-select
                            v-model="Bike.brand"
                            outlined
                            dense
                            hide-details
                            label="Brand"
                            class="custom-text-field right-border"
                            :items="Brands"
                            :return-object="false"
                            item-text="value"
                            item-value="value"
                            :menu-props="{ bottom: true, offsetY: true }"
                        >
                        </v-select>

                      </v-col>
                      <v-col cols="12" md="6" class="ps-md-0">
                        <v-select
                            v-model="Bike.category"
                            outlined
                            dense
                            hide-details
                            label="Category"
                            class="custom-text-field"
                            :items="Categories"
                            :return-object="false"
                            item-text="value"
                            item-value="value"
                            :menu-props="{ bottom: true, offsetY: true }"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="pe-md-0">
                        <v-text-field
                            v-model="Bike.mileage"
                            outlined
                            type="number"
                            dense
                            hide-details
                            label="Mileage"
                            class="custom-text-field right-border"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="ps-md-0">
                        <v-text-field
                            v-model="Bike.model"
                            outlined
                            hide-details
                            dense
                            label="Model"
                            class="custom-text-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="pe-md-0">
                        <v-text-field
                            v-model="Bike.images"
                            outlined
                            dense
                            label="Images"
                            hide-details
                            class="custom-text-field right-border"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="ps-md-0">
                        <v-text-field
                            v-model="Bike.price"
                            type="number"
                            outlined
                            hide-details
                            dense
                            label="Price"
                            class="custom-text-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </fieldset>
                </div>
              </div>
              <div class="row pb-0 mt-7">
                <div class="col-12 pb-0">
                  <fieldset class="fieldsetStyle" label="Bike" outlined>
                    <legend class="field-legend">Engine</legend>
                    <div>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-select
                              v-model="Bike.engineType"
                              outlined
                              dense
                              hide-details
                              label="Engine Type"
                              class="custom-text-field right-border"
                              :items="EngineType"
                              :return-object="false"
                              item-text="text"
                              item-value="value"
                              :menu-props="{ bottom: true, offsetY: true }"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.displacement"
                              outlined
                              hide-details
                              dense
                              label="Displacement"
                              class="custom-text-field"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-text-field
                              v-model="Bike.fuelCapacity"
                              outlined
                              type="number"
                              dense
                              label="Fuel Capacity"
                              hide-details
                              class="custom-text-field right-border"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.fuelSupply"
                              outlined
                              dense
                              label="Fuel Supply"
                              hide-details
                              class="custom-text-field "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-text-field

                              v-model="Bike.ignition"
                              outlined
                              hide-details
                              dense
                              label="Ignition"
                              class="custom-text-field right-border"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.topSpeed"
                              type="number"
                              outlined
                              dense
                              label="Top Speed"
                              hide-details
                              class="custom-text-field"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-text-field

                              v-model="Bike.starting"
                              outlined
                              hide-details
                              dense
                              label="Starting"
                              class="custom-text-field right-border"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.finalDrive"
                              outlined
                              dense
                              label="Final Drive"
                              hide-details
                              class="custom-text-field"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-text-field

                              v-model="Bike.gearShiftingType"
                              outlined
                              hide-details
                              dense
                              label="Gear Shifting Type"
                              class="custom-text-field right-border"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.engineMode"
                              outlined
                              dense
                              label="Engine Mode"
                              hide-details
                              class="custom-text-field"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-text-field

                              v-model="Bike.boreStroke"
                              outlined
                              hide-details
                              dense
                              label="Bore Stroke"
                              class="custom-text-field right-border"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.compressionRatio"
                              outlined
                              dense
                              label="Compression Ratio"
                              hide-details
                              class="custom-text-field"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-text-field

                              v-model="Bike.ratedOutput"
                              outlined
                              hide-details
                              dense
                              label="Rated Output"
                              class="custom-text-field right-border"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.maxTorque"
                              outlined
                              dense
                              label="Max Torque"
                              hide-details
                              class="custom-text-field"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-text-field

                              v-model="Bike.lubrication"
                              outlined
                              hide-details
                              dense
                              label="Lubrication"
                              class="custom-text-field right-border"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.clutch"
                              outlined
                              dense
                              label="Clutch"
                              hide-details
                              class="custom-text-field"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pe-md-0">
                          <v-text-field

                              v-model="Bike.coolingSystem"
                              outlined
                              hide-details
                              dense
                              label="CoolingSystem"
                              class="custom-text-field right-border"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="ps-md-0">
                          <v-text-field
                              v-model="Bike.valveTrain"
                              outlined
                              dense
                              label="Valve Train"
                              hide-details
                              class="custom-text-field"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 grey lighten-5">
              <fieldset class="fieldsetStyle">
                <legend class="field-legend">Body</legend>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.frame"
                        type="text"
                        outlined
                        dense
                        hide-details
                        label="Frame"
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.wheelBase"
                        outlined
                        dense
                        hide-details
                        label="Wheel Base"
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.roadReadyWeight"
                        outlined
                        dense
                        hide-details
                        type="number"
                        label="Road Ready Weight"
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.groundClearance"
                        label="Ground Clearance"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.length"
                        label="Length"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.height"
                        label="Height"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.totalWeight"
                        label="Total Weight"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.unLadenWeight"
                        label="UnLaden Weight"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.width"
                        label="Width"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.seatHeight"
                        label="Seat height"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.color"
                        label="Color"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="Bike.reserve"
                        label="Reserve"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field

                        label="Frame"
                        outlined
                        dense
                        hide-details
                        class="custom-text-field"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
            </div>
          </div>
          <div class="col-12 pa-0 mt-5">
            <fieldset class="fieldsetStyle">
              <legend class="field-legend">Chassis</legend>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="Bike.frontSuspension"
                      type="text"
                      outlined
                      dense
                      hide-details
                      label="Front Suspension"
                      class="custom-text-field"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="Bike.frontSuspensionTravel"
                      type="text"
                      outlined
                      dense
                      hide-details
                      label="Front Suspension Travel"
                      class="custom-text-field"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="Bike.rearSuspension"
                      type="text"
                      outlined
                      dense
                      hide-details
                      label="Rear Suspension"
                      class="custom-text-field"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="Bike.rearSuspensionTravel"
                      type="text"
                      outlined
                      dense
                      hide-details
                      label="Rear Suspension Travel"
                      class="custom-text-field"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="Bike.frontBrake"
                      type="text"
                      outlined
                      dense
                      hide-details
                      label="Front Brake"
                      class="custom-text-field"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="Bike.rearBrake"
                      type="text"
                      outlined
                      dense
                      hide-details
                      label="Rear Brake"
                      class="custom-text-field"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="Bike.frontTyre"
                      type="text"
                      outlined
                      dense
                      hide-details
                      label="Front Tyre"
                      class="custom-text-field"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="Bike.rearTyre"
                      type="text"
                      outlined
                      dense
                      hide-details
                      label="Rear Tyre"
                      class="custom-text-field"
                  ></v-text-field>
                </v-col>
              </v-row>
            </fieldset>
          </div>
          <div class="col-12 pa-0 mt-5">
            <fieldset class="fieldsetStyle">
              <legend class="field-legend">Videos</legend>
              <v-row>
                <v-col cols="8">
                  <v-data-table disable-sort :items="Bike.videos" :headers="VideosHeader" hide-default-footer>
                    <template v-slot:[`item.sno`]="{ index }">
                      <span>{{ (index += 1) }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ index }">
                      <i @click="DeleteUrl(index)"
                         class="custom-icon mgc_delete_2_line action-icon-size red--text text--darken-2 me-2"
                      ></i>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="4" class="d-flex justify-start">
                  <v-btn
                      @click="VideoUrl = '',UrlDialog = true"
                      depressed
                      dark
                      class="align-self-start grey darken-2 text-capitalize">
                    Add Url
                  </v-btn>
                </v-col>
              </v-row>
            </fieldset>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="UrlDialog"
        max-width="400"
    >
      <v-card>
        <v-card-title>
          Add Video Url
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="VideoUrl" dense hide-details outlined label="Url"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="grey"
              text
              class="text-capitalize"
              outlined
              depressed
              @click="UrlDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
              depressed
              color="green darken-2"
              class="text-capitalize"
              @click="UrlDialog = false, AddUrl()"
              :disabled="!VideoUrl"
          >
            <span class="add-text">Add</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-circular
        v-if="loader"
        :size="25"
        :width="2"
        color="success darken-2"
        indeterminate
    ></v-progress-circular>

  </v-container>
</template>
<script>
import axios from "axios";
import config from "../../Configuration/config";

export default {
  name: "AddBike",
  data() {
    return {
      loader: false,
      UrlDialog: false,
      VideoUrl: "",
      EngineType: [
        {text: "Petrol", value: 100},
        {text: "Electric", value: 200},
      ],
      VideosHeader: [
        {
          text: "#",
          value: "sno",
          width: "50px",
        },
        {
          text: "URL",
          value: "videoUrl"
        },
        {
          text: "Action",
          value: "actions",
          width: "70px",
          align: "center"
        }
      ],
      Brands: [],
      Categories: [],
      Bike: {
        "bikeId": 0,
        "brand": "",
        "category": "",
        "model": "",
        "mileage": 0,
        "fuelCapacity": 0,
        "engineType": 100,
        "topSpeed": 0,
        "color": "",
        "displacement": "",
        "starting": "",
        "ignition": "",
        "finalDrive": "",
        "gearShiftingType": "",
        "engineMode": "",
        "boreStroke": "",
        "compressionRatio": "",
        "ratedOutput": "",
        "maxTorque": "",
        "lubrication": "",
        "fuelSupply": "",
        "clutch": "",
        "coolingSystem": "",
        "valveTrain": "",
        "frontSuspension": "",
        "frontSuspensionTravel": "",
        "rearSuspension": "",
        "rearSuspensionTravel": "",
        "frontBrake": "",
        "rearBrake": "",
        "rearTyre": "",
        "frontTyre": "",
        "frame": "",
        "wheelBase": "",
        "usableTankVolume": "",
        "roadReadyWeight": "",
        "groundClearance": "",
        "length": "",
        "reserve": "",
        "height": "",
        "totalWeight": "",
        "unLadenWeight": "",
        "width": "",
        "seatHeight": "",
        "images": "",
        "videos": [],
        "price": 0,
        "views": 0,
        "likes": 0,
        "rating": 0
      },
    };
  },
  methods: {
    AddUrl() {
      let object = {
        videoUrl: this.VideoUrl
      };
      this.Bike.videos.push(object);
    },
    async AddBike() {
      try {
        this.loader = true;
        let response = await axios({
          method: "Post",
          url: config.apiBaseUrl + "api/Bike/AddOrUpdate",
          data: this.Bike,
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        });
        this.loader = false;
        this.$router.push('/home')
        console.log(response)
      } catch (e) {
        this.loader = false;
        console.log(e)
      }
    },
    async GetBrands() {
      try {
        this.loader = true;
        let response = await axios({
          method: "Get",
          url: config.apiBaseUrl + "api/Keyvalue/All",
        });

        this.Brands = response.data.filter(obj => obj.type === 10);
        this.Categories = response.data.filter(obj => obj.type === 20);
        this.loader = false;
      } catch (err) {
        this.loader = false;
        console.log(err);
      }
    },
    DeleteUrl(index) {
      this.Bike.videos.splice(index, 1);
    },
    async GetBike() {
      try {
        let fd = new FormData();
        fd.append("bikeId", this.$route.params.item.bikeId);
        this.loader = true;
        let response = await axios({
          method: "Post",
          url: config.apiBaseUrl + "api/Bike/Get",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          }
        });
        this.loader = false;
        this.Bike = response.data.data;
      } catch (e) {
        this.loader = false;
        console.log(e)
      }
    },
  },
  created() {
    this.GetBrands();
    if (this.$route.params.item != undefined) {
      this.GetBike();
    }
  }
};
</script>
<style scoped>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}

.prepend-select >>> .v-input__slot::before {
  border-style: none !important;
}

.smoothShadow {
  box-shadow: -10.2px -2.8px 0.8px rgba(0, 0, 0, -0.006),
  -8px -2.1px 5px rgba(0, 0, 0, -0.006),
  -2.2px -0.6px 13px rgba(0, 0, 0, -0.003),
  6.7px 1.8px 25.6px rgba(0, 0, 0, 0.004),
  20.5px 5.5px 44.7px rgba(0, 0, 0, 0.016), 52px 14px 80px rgba(0, 0, 0, 0.05) !important;
}

.fieldsetStyle {
  border: 1px solid #ccc; /* Add a border */
  border-radius: 0px; /* Add a border radius */
  padding: 15px; /* Add some padding */
}

.field-legend {
  padding: 0px 10px !important;
  font-size: 22px;
  font-weight: bold;
}

.custom-height {
  height: 100% !important;
}

.add-text {
  color: #ffffff !important;
}
</style>
