<template>
  <div>
    <v-container fluid class="pa-8">
      <v-row class="">
        <v-col cols="12" sm="6" md="7">
          <h3 class="font-weight-medium">Bikes</h3>
        </v-col>
        <!-- Add User Dialog -->

        <!-- Add User Dialog End -->
        <v-col class="d-flex pa-0">
          <v-text-field
              append-icon="mdi-magnify"
              type="text"
              name="search"
              label="Search"
              outlined
              dense
              color="dark"
              clearable
              hide-details
              @keyup.enter="GetAll()"
              v-model="Filter.filterText"
              @click:clear="(Filter.filterText = ''), GetAll()"
              @click:append="GetAll()"
          ></v-text-field>
          <v-btn
              depressed
              dark
              height="40"
              class="customBtnColor ms-3 me-3 caption text-capitalize"
              to="/addBike"
          >
            <v-icon small class="me-2">mdi-plus-circle</v-icon>
            Add Bike
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="mt-5 mt-md-1"></v-divider>
      <v-row class="mt-3 mt-md-0 pa-0">
        <v-col class="pa-0 pa-md-3">
          <v-data-table
              disable-sort
              v-if="this.bikes == null"
              :headers="headers"
              class="elevation-0"
              hide-default-footer
              :items-per-page="Filter.pageSize"
          ></v-data-table>
          <v-data-table
              v-else
              disable-sort
              :headers="headers"
              :items="bikes"
              class="elevation-0"
              hide-default-footer
              :items-per-page="Filter.pageSize"
          >
            <template v-slot:[`item.sno`]="{ index }">
              <span>
                {{ (index += 1) }}
              </span>
            </template>
            <template v-slot:[`item.engineType`]="{ item }">
              <span v-if="item.engineType == 100">Petrol </span>
              <span v-if="item.engineType == 200">Electric</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <i
                    v-on="on"
                    v-bind="attrs"
                    @click="takeData(item), copyText(item)"
                    class="custom-icon mgc_link_2_line action-icon-size blue--text text--darken-3 me-2"
                  ></i>
                </template>
                <span>Copy Magic Url</span>
              </v-tooltip> -->
              <i
                  @click="takeData(item), (DeleteDialog = true)"
                  class="custom-icon mgc_delete_2_line action-icon-size red--text text--darken-2 me-2"
              ></i>
              <router-link
                  class="ms-0" :to="{ name: 'AddBike', params: { item } }"
              >
                <i class="custom-icon mgc_edit_line action-icon-size green--text text--darken-2 me-2"
                ></i>
              </router-link>
            </template>
          </v-data-table>
          <v-divider class="mt-2"></v-divider>
          <v-row class="no-gutters mt-2" align="center">
            <v-spacer style="width: 75%"></v-spacer>

            <span class="pagination-text">Rows per page</span>
            <v-select
                :items="options"
                v-model="Filter.pageSize"
                dense
                item-value="value"
                class="ma-2"
                style="width: 50px"
                @change="GetAll"
            ></v-select>

            <span
                v-if="pagination != null"
                class="mr-4 grey--text pagination-text"
            >
              Page {{ pagination.page }} of {{ pagination.totalPages }}
            </span>

            <v-icon
                small
                dark
                color="success darken-1"
                class="mr-1"
                @click="backwardButtonCall"
            >mdi-chevron-left
            </v-icon
            >

            <v-icon
                small
                dark
                color="success darken-1"
                @click="forwardButtonCall()"
                class="ml-1"
            >mdi-chevron-right
            </v-icon
            >
          </v-row>

          <!-- Delete Dialog -->
          <v-dialog v-model="DeleteDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="text-h5"> Delete Campaign</v-card-title>
              <v-card-text class="subtitle-1"
              >Are You sure? You want to delete selected bike.
              </v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="grey lighten-3 text-capitalize"
                    text
                    @click="DeleteDialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    class="error darken-2 text-capitalize"
                    text
                    @click="DeleteBike(), (DeleteDialog = false)"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-progress-circular
          v-if="loader == true"
          :size="25"
          :width="2"
          color="success darken-2"
          indeterminate
      ></v-progress-circular>
      <v-snackbar v-model="deleteMessage" color="success" :top="true" :timeout="1000">Successfully Deleted</v-snackbar>

    </v-container>
  </div>
</template>
<script>
import config from "../../Configuration/config";
import axios from "axios";

export default {
  name: "BikesComp",
  data() {
    return {
      deleteMessage: false,
      loader: true,
      bikes: [],
      DeleteDialog: false,
      asinLink: "",
      currentBike: [],
      Keywords: [],
      headers: [
        {text: "#", value: "sno"},
        {text: "Brand", value: "brand", align: "start"},
        {text: "Category", value: "category", align: "start"},
        {text: "Price", value: "price", align: "start"},
        {text: "Engine", value: "engineType", align: "start"},
        {text: "Displacement", value: "displacement", align: "start"},
        {text: "Mileage", value: "mileage", align: "start"},
        {text: "Model", value: "model", align: "start"},
        {text: "Color", value: "color", align: "start"},
        {text: "Action", value: "actions", align: "center"},
      ],
      page: 1,
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      pagination: null,
      Filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
      },
    };
  },
  methods: {
    takeData(bike) {
      this.currentBike = bike;
    },
    async GetAll() {
      try {
        this.loader = true;
        await axios({
          method: "Post",
          url: config.apiBaseUrl + "api/Bike/GetAll",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }).then((response) => {
          this.loader = false;
          this.bikes = response.data.data;
        });
      } catch (err) {
        this.loader = true;
        console.log(err);
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
    async DeleteBike() {
      try {
        let fd = new FormData();
        fd.append("bikeId", this.currentBike.bikeId);
        this.loader = true;
        let response = await axios({
          method: "Post",
          url: config.apiBaseUrl + "api/Bike/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          }
        });
        this.loader = false;
        console.log(response);
        this.GetAll();
      } catch (e) {
        this.loader = false;
        console.log(e)
      }
    },
    CheckToken() {
      if (
          axios.defaults.headers.common["Authorization"] == null ||
          axios.defaults.headers.common["Authorization"] == "Bearer " ||
          axios.defaults.headers.common["Authorization"] == "Bearer " + null
      ) {
        this.$router.push("/");
      }
    },
  },
  created() {
    this.CheckToken();
    this.GetAll();
  },
};
</script>
<style scoped>
.asin-link {
  text-decoration: underline;
  font-size: 14px;
}

.pagination-text {
  font-size: 12px !important;
}

.menu-page {
  text-transform: inherit !important;
}
</style>
