import { render, staticRenderFns } from "./HeaderComp.vue?vue&type=template&id=fc5355da&scoped=true&"
import script from "./HeaderComp.vue?vue&type=script&lang=js&"
export * from "./HeaderComp.vue?vue&type=script&lang=js&"
import style0 from "./HeaderComp.vue?vue&type=style&index=0&id=fc5355da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!C:\\Users\\Mobivone\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc5355da",
  null
  
)

export default component.exports