import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import 'remixicon/fonts/remixicon.css'
import axios from "axios";
import config from "./Configuration/config";

// //Vue toast
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  // Setting the global default position
  position: POSITION.TOP_CENTER,
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
});
axios.defaults.baseURL = config.apiBaseUrl;
store.state.isLoggedIn = localStorage.getItem("isLoggedin");
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
Vue.config.productionTip = false;





new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");

