<template>
  <div>
    <v-app-bar class="  custom-appbar "  dense dark elevation="0" app clipped-left outlined>
      <div class="d-flex align-center ">
        <v-btn icon @click="drawer = !drawer">
          <i class="mdi mdi-menu title" ></i>
        </v-btn>
        <h4 class="logoFont">BIKEZ</h4>
      </div>
      <v-spacer></v-spacer>
      <span class="heading mr-2" v-if="User != null">{{User.fullName}}</span>
      <v-icon color="" >mdi-account </v-icon>
      <v-menu offset-y transition="slide-y-reverse-transition">
        <template v-slot:activator="{ on, attrs }">
             <v-icon color="white " v-bind="attrs"
            v-on="on"> mdi-chevron-down </v-icon>    
        </template>

        <v-list dense>
          <v-list-item class="ps-5 pe-5" dense link @click="dialog = true">
            <i class="mgc_user_1_line btn-icon-size me-3"></i>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item class="ps-5 pe-5" dense link @click="logout()">
            <i class="mgc_exit_door_line btn-icon-size me-3"></i>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!--      <v-icon color="success darken-2" large>mdi-account-circle </v-icon>-->
<!--      <v-menu offset-y transition="slide-y-reverse-transition">-->
<!--        <template v-slot:activator="{ on, attrs }">-->
<!--          <div-->
<!--              class="grey lighten-4 me-3 account-btn"-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--          >-->
<!--            <i class="mgc_user_1_line prepend-icon-size"></i>-->
<!--          </div>-->
<!--        </template>-->

<!--        <v-list dense>-->
<!--          <v-list-item class="ps-5 pe-5" dense link @click="dialog = true">-->
<!--            <i class="mgc_user_1_line btn-icon-size me-3"></i>-->
<!--            <v-list-item-title>Profile</v-list-item-title>-->
<!--          </v-list-item>-->
<!--          <v-list-item class="ps-5 pe-5" dense link @click="logout()">-->
<!--            <i class="mgc_exit_door_line btn-icon-size me-3"></i>-->
<!--            <v-list-item-title>Logout</v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
<!--      </v-menu>-->
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        mini-variant
        mini-variant-width="120px"
        app
        clipped
        class="grey lighten-4 pa-0 ma-0"
    >
      <v-list nav class="d-flex flex-column align-center mt-1">
        <v-list-item-group
            active-class="active-icon"
            color="grey darken-2"
            class="pa-0 ma-0 sidebar"
        >
          <v-list-item to="/home" link class="active">
            <v-list-item-icon class="d-flex flex-column align-center">
              <i class="mgc_qrcode_line"></i>
              <span class="nav-item-title px-6 text--white">Bikes</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item to="/magicurl" link>
            <v-list-item-icon class="d-flex flex-column align-center">
              <i class="ri-anticlockwise-line"></i>
              <span class="nav-item-title">links</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/links" link>
            <v-list-item-icon class="d-flex flex-column align-center">
              <i class="ri-links-line"></i>
              <span class="nav-item-title">Link Rotator</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/domains" link>
            <v-list-item-icon class="d-flex flex-column align-center">
              <i class="mgc_world_2_line"></i>
              <span class="nav-item-title">Domains</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/trackcodes" link>
            <v-list-item-icon class="d-flex flex-column align-center">
              <i class="ri-code-s-slash-line"></i>
              <span class="nav-item-title">Track Codes</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="Role== 10 || Role == 20"
              to="/users"
              link
          >
            <v-list-item-icon class="d-flex flex-column align-center">
              <i class="mgc_user_1_line"></i>
              <span class="nav-item-title">Users</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- <v-list-item v-if="User.role == 10" to="/appsumo" link>
            <v-list-item-icon class="d-flex flex-column">
              <v-icon>mdi-code-tags-check</v-icon>
              <span class="nav-item-title">AppSumo Code</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"  md="12" class="pa-0">
                <v-text-field
                    label="FullName*"
                    outlined
                    dense
                    hide-details
                    v-if="User != null"
                    v-model="User.fullName"
                    class="mb-2"
                ></v-text-field>
                <v-text-field
                v-if="User != null"
                    disabled
                    v-model="User.username"
                    label="Username"
                    outlined
                    dense
                    hide-details
                    
                class="mb-2"

                ></v-text-field>
                <v-text-field
                    v-if="User != null"
                    v-model="User.password"
                    label="Password"
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="me-1">
          <v-spacer></v-spacer>
          <v-btn class="grey lighten-3 font-weight-bold caption text-capitalize" depressed @click="dialog = false">
            Close
          </v-btn>
          <v-btn
              class="customBtnColor white--text caption text-capitalize" depressed
              @click="updateUser(), (dialog = false)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/Configuration/config";

export default {
  data() {
    return {
      User: null,
      drawer: true,
      dialog: false,
    };
  },
  methods: {
    getuser() {
      axios.get(config.apiBaseUrl + "api/User/Get").then((res) => {
        this.User = res.data;

      });
    },
    logout() {
      axios.get(config.apiBaseUrl + "api/User/Logout").then(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        (axios.defaults.headers.common["Authorization"] = null),
            (this.$store.state.isLoggedIn = false);
        localStorage.setItem("isLoggedin", false);
        this.$router.push("/");
      });
    },
    updateUser() {
      try {
        axios
            .post(config.apiBaseUrl + "api/User/Update", {
              username: this.User.username,
              fullName: this.User.fullName,
              password: this.User.password,
            })
            .then(() => {
              this.$toast.success("Profile updated successfully.", {
                position: "top-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
      } catch (err) {
        this.$toast.warning("Something went wrong!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
  },
  beforeMount() {
    this.Role = localStorage.getItem("Role");
  },
  created() {

    this.getuser();
  },
};
</script>
<style scoped>
.header {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.active-icon i.v-icon.v-icon {
  color: grey !important;
}

.active-icon span {
  color: grey !important;
}

.main-color {
  background-color: grey !important;
  color: grey !important;
}

.v-sheet {
  padding: 0 !important;
  height: none !important;
}

.sidebar .v-list-item {
  margin: 0 !important;
  padding: 0 5px 0 5px !important;
}
</style>
